iframe {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

img {
  max-width: 100%;
}

.fullWidth {
  width: 100%;
}
